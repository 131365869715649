import React, { useContext, useState } from "react";
import { Text, View, Platform, ScrollView, Image } from 'react-native'
import { DEFAULT_PAD, TEXT_B, BUTTON_PRIMARY, BUTTON_FLAT_LINK, DARK_GRAY, getFont, DEFAULT_FONT } from "../../style";
import { TextButton } from "../General/Other";
import { UserContext } from '../../utils/context'
import { CustomModal } from '../General/Other'
import { DisclaimerConteiner } from '../SettingsLogin/Disclaimer'

export default function StartConfigurator(props) {

    const { languages, code,  setMessage, user } = useContext(UserContext);
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <ScrollView style={{paddingTop: DEFAULT_PAD}}>
            { Platform.OS === 'web' && <CustomModal 
                titleModal={'Title'}
                innerComponet={<DisclaimerConteiner />}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/> }
            <View style={{alignItems: 'center'}}>
                <Text style={[ getFont(32, DARK_GRAY, DEFAULT_FONT), {
                marginVertical: DEFAULT_PAD, width: "80%", lineHeight: 30, 
                textAlign: 'left'}]}>
                    {languages["title_configurator"][code]}
                </Text>
                <Text style={[ TEXT_B, {width: "80%", marginVertical: DEFAULT_PAD} ]}>
                    {languages["body_configurator"][code]}
                </Text>
                <View>
                    
                    <TextButton 
                        title={languages["alert_configurator_title"][code]}
                        onPress={() => { 
                            Platform.OS === 'web' ? setModalVisible(true) : props.navigation.push('Disclaimer')
                        }}  
                        type={BUTTON_FLAT_LINK}/>
                </View>
                
                <TextButton extra_style={{width: "auto"}}
                    title= {languages["start"][code]}
                    onPress={() => props.startConfigurator(true, "home")} 
                    type={BUTTON_PRIMARY}/>
                
                
                
                <View style={{width: '100%', height: 100 }}/>
            </View>
            <View style={{alignItems: 'center'}}>
                <Image
                    source={require('../../images/kit_bg_green.png')} 
                    resizeMode="cover" 
                    style={{ justifyContent:'center', width:'130%', height: '40%', 
                        position: 'relative', top: -10, left: 0, 
                        borderBottomRightRadius: 300, 
                        borderBottomLeftRadius: 0 }}/>
                <Text style={[ getFont(32, DARK_GRAY, DEFAULT_FONT), {
                    marginVertical: 10, width: "80%",lineHeight: 50}]}>
                    {languages["start_kit_title_kit"][code]}
                </Text>
                <TextButton extra_style={{width: "60%"}}
                    title={languages["start"][code]}
                    onPress={() => user ? 
                        props.startConfigurator(true, "kit") : 
                        setMessage({message: languages["element_not_save"][code], type: "ERROR"})} 
                    type={BUTTON_PRIMARY}/>
                <Text style={[ TEXT_B, {width: "80%", marginBottom: 20} ]}>
                    {languages["start_kit_body_kit"][code]}
                </Text>
            
        </View>
        </ScrollView>
    )

    
}