import { View, Text, Platform } from 'react-native';
import { useContext } from 'react'
import { getFont, GREEN, TITLE_B, MEDIUM_B, DEFAULT_FONT, MEDIUM_W, RED_TRANSPARENT, RED } from "../../../style";
import { UserContext } from '../../../utils/context'

export default function AlertMessage(props) {
    //TODO lingua
    const { languages, code } = useContext(UserContext);
    return (
        <View style={{marginHorizontal: Platform.OS !== 'web' ? 10 : 0}}>
            <Text style={TITLE_B}>{languages["select_fertilizer_title"][code]}</Text>
            <View style={{padding: 12, borderColor: GREEN, borderRadius: 5, borderWidth: 2, marginVertical: 12}}>
                <Text style={TITLE_B}>{props.fertilizer.name}</Text>
                <View style={{flexDirection: 'row', marginVertical: 5}}>
                    <Text style={{...MEDIUM_W, backgroundColor: GREEN, marginRight: 10}}>
                        {languages["vendor"][code].toUpperCase()}
                    </Text>
                    <Text style={{...MEDIUM_B }}>
                        {props.fertilizer.vendor}
                    </Text>
                </View>
                
                
            </View>
            
            <Text style={{...getFont(22, GREEN, DEFAULT_FONT), marginVertical: 10}}>
                {languages["check_product_txt"][code]}
            </Text>
            { props.fertilizer.is_inibitore && 
                <Text style={getFont(15, RED, DEFAULT_FONT)}>
                    {languages["is_inibitore_message"][code]}
                </Text> }
        </View>
    )
}