import React, { useState, useContext} from "react";
import { View } from 'react-native'
import Settings from './Settings'
import { TextButton } from '../General/Other'
import { BUTTON_FLAT, BUTTON_FLAT_GRAY, BUTTON_SECONDARY  } from "../../style";
import { UserContext } from '../../utils/context'
import ChiSiamo from './ChiSiamo';
import ChooseMetrics from './ChooseMetrics';
import UserContainer from './User/UserContainer'
import ChooseLang from './ChooseLang';
import Favorites from './Favorites'
import ForgotPwd from "./User/ForgotPwd";
import { DisclaimerConteiner } from './Disclaimer'
import _ from 'underscore'



export default function SettingsLogin(p) {

    const appcontext = useContext(UserContext);
    const [whoShow, setWhoShow] = useState("container_settings");
    const [params, setParams] = useState(null);

    const [layers, setLayers] = useState(["container_settings"]);

    const chooseTitleForUserContainer = (what) => {
        switch (what) {
            case "login":
                return appcontext.languages["login"][appcontext.code]
            case "signup":
                return "registra utente"
            default:
                return ""
        }
    }

    const bread = {
        "container_settings": {
            "component": <Settings {...p} onClickOnRow={(w, p) => {
                setWhoShow(w)
                setParams(p)
                setLayers([...layers, w])
            }} />,
            "title": appcontext.languages["settings"][appcontext.code]
        },
        "ChiSiamo": {
            "component": <ChiSiamo {...p}/>,
            "title": appcontext.languages["about"][appcontext.code]
        },
        "ChooseLang": {
            "component": <ChooseLang {...p} />,
            "title": appcontext.languages["change_lang"][appcontext.code]
        }, 
        "ChooseMetrics": {
            "component": <ChooseMetrics {...p} />,
            "title": appcontext.languages["change_measurament"][appcontext.code] 
        },
        "Disclaimer": {
            "component": <DisclaimerConteiner {...p} />,
            "title": appcontext.languages["disclaimer_title"][appcontext.code] 
        },
        "UserContainer": {
            "component": <UserContainer {...p} params={params} onOk={() => {
                setWhoShow("container_settings")
                setLayers(["container_settings"])
            }} 
                onPwdForgotClick={() => {
                    setWhoShow("ForgotPwd")
                    setLayers([...layers, "ForgotPwd"])
                }}/> ,
            "title": params ? chooseTitleForUserContainer(params.what) : ''
        },
        "Favorites": {
            "component": <Favorites {...p} onFavoriteClick={(f) => p.onFavoriteClick(f)}/>,
            "title": appcontext.languages["history"][appcontext.code]
        },
        "ForgotPwd": {
            "component": <ForgotPwd />,
            "title": "Reset password"
        },
    }    

    return (
        <View style={{ marginHorizontal: '10%' }}>
            { layers.length > 0 && <View style={{flexDirection: 'row', marginVertical: 10}}>
                { layers.map( (layer, index) => 
                
                    <TextButton 
                        key={index} 
                        type={ index === layers.length-1 ? BUTTON_FLAT_GRAY : BUTTON_FLAT } 
                        disabled={index === layers.length-1}
                        title={ (index === 0 ? "" : "  /  ") + bread[layer].title.toUpperCase()} 
                        onPress={() => {
                            const newLayers = _.first(layers, index+1)
                            setLayers(newLayers)
                            const a = _.last(newLayers)
                            setWhoShow(a)
                        }}/>
                )}
            </View> }
            <View style={{padding: 0}}>
                { bread[whoShow].component }
            </View>
            { layers.length > 1 && <View>
                <TextButton type={BUTTON_SECONDARY} title='OK' onPress={() => {
                    setLayers(["container_settings"])
                    setWhoShow('container_settings')
                }}/>
            </View> }
        </View>
    )
}