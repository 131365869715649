import _ from 'underscore'

//TODO
//italiano, inglese, tedesco, spagnolo e francese.
export const language_published = [
    'it_it',
    'en_us',
    'de_de',
    'es_es',
    'fr_fr'
]
export const codes  = ["it_it", "en_us", "es_es", 
    "de_de", "ru_ru", "fr_fr", 
    "el_el", "fi_fi", "af_af", 
    "ro_ro", 
    "lt_lt", 
    "cz_cz", "pl_pl", "ua_ua", "lv_lv"]


export const flags = ["it", "gb", "es", "de", "ru", "fr", "gr", "fi", "af", "ro", "lt", "cz", "pl", "ua", "lv"]


export const names = ["Italiano", 
    "English", 
    "Español", 
    "Deutsch", 
    "Pусский", 
    "Français",
     "Ελληνικά", 
     "Suomalainen", 
     "Afrikaans", 
     "Româna", 
     "Lietuvių", 
     "čeština", "Polski", "Yкраїнська", "Latviski"]

export const languages = _.zip(codes, flags, names)
    .map( el => {
        return {"code": el[0], "flag": el[1], "name": el[2]}
    }).filter( el => language_published.includes(el['code'])) 

console.log(languages)

export const getMapLanguages = () => {
    var new_map = {}
    languages.forEach( el => {
        new_map[el.code] = el
    })
    return new_map
}



