import React, { useEffect, useState, useContext }  from "react";
import { View, TextInput, ScrollView } from 'react-native';
import { getFertilizer, getSearchSimilarityFertilizer } from '../../../api/dcm_api';
import { container, DEFAULT_PAD, DARK_GRAY } from '../../../style'
import FertilizerRow from './FertilizerRow'
import { CustomIcon } from "../../General/CustomIcon";
import { handleLoading } from '../../Need/'
import { UserContext } from '../../../utils/context'
import { EmptyResult } from '../../General/Other'


export function SearchTextInput(p) {

    const { code, languages } = useContext(UserContext);
    const label = languages["placeholder_search_text"][code]

    return (
        <View style={[container.search, {flexDirection:'row'}]}>
            <CustomIcon style={{marginHorizontal: 12}} styleWeb={{margin: 12}} name={'search'} size={20} color={DARK_GRAY} />
            <TextInput textAlign={'left'} style={{flex:1}}
                value={p.search}
                placeholder={label}  
                onChangeText={(e) => p.onChangeText(e)}/> 
        </View>   
    )
}


export default function Fertilizer(props) {

    const { code, languages, setMessage } = useContext(UserContext);
    const [selected, setSelected] = useState(props.fertilizer);
    const [search, setSearch] = useState("");
    const [messageError, setMessageError] = useState(null);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
    
    useEffect(() => {
        props.isValid(false)
        setStateApi({isLoading: true, error: null, response: []})
        if (props.standardSearch) {
            props.idFamily !== -1 && props.idMachine !== -1 && 
                getFertilizer(code, props.idFamily, props.idMachine, props.blade ? 1 : 0).then( success => {
                    if (success.response) {
                        props.isValid( props.fertilizer ? props.fertilizer.id !== -1 : false)
                        setStateApi({isLoading: false, error: null, response: success.response})
                    } else {
                        setStateApi({isLoading: false, error: success.error, response: []})
                    }
                })
        } else {
            (props.paramForSearch &&
                getSearchSimilarityFertilizer(code, 
                    props.paramForSearch.distribution, 
                    props.paramForSearch.kglt, 
                    props.paramForSearch.selectedId, 
                    props.paramForSearch.idMachine)
                    .then( success => {
                        if (success.response) {
                            setStateApi({isLoading: false, error: null, response: success.response.records})
                            setMessageError(success.response.message)
                        } else {
                            setStateApi({isLoading: false, error: success.error, response: []})
                            setMessageError(null)
                        }
                }) )
        }
    }, [])

    

    return (        
        <ScrollView style={{marginHorizontal: 10}}>
            <View style={{marginBottom: DEFAULT_PAD/2}}>
                <SearchTextInput search={search} onChangeText={ t => setSearch(t)}/>
            </View>
             { handleLoading(props.langSigla, stateApi.isLoading, stateApi.error, 
                stateApi.response && stateApi.response.length === 0 ? 
                messageError ? <EmptyResult message={'todo'}/> : <EmptyResult message={languages["no_result"][code]}/> : 
                <View>
                    { stateApi.response
                    .filter(f => (f.name && f.name.toLowerCase().includes(search.toLowerCase()))
                        || (f.vendor && f.vendor.toLowerCase().includes(search.toLowerCase()))
                        || (f.country && f.country.toLowerCase().includes(search.toLowerCase())))
                    .map( (item, index) => 
                        <FertilizerRow 
                            onPushRow={(fertilizer) => {
                                props.onSelectFertilizer(fertilizer)
                                setSelected(fertilizer)
                                props.isValid(true)
                                if (fertilizer.is_multi_country) {
                                    setMessage({message:  languages["is_inibitore_message"][code] + " " + languages["no_result"][code], 
                                    type: "WARNING"}) 
                                }
                                /*if (fertilizer.is_inibitore) {
                                    setMessage({message: languages["is_multicountry_message"][code] + " " +  languages["no_result"][code], 
                                    type: "WARNING"}) 
                                }*/
                                
                                 
                            }}
                            onPushInfo={ f => props.navigation && props.navigation.push('Info', { fertilizer: f}) } 
                            item={item} 
                            key={index} 
                            selectedId={ selected ? selected.id : -1} /> 
                        )}
                </View>
                ) } 
        </ScrollView>
        
    )
}